<template>
  <screen_width_cont>
    <div class="cont_300_basico ancho_acotado">
      <div class="header_top">
        <headerBack @back="backLocal" />
      </div>

      <div class="idiomaTitle title">{{ t("app.idioma.tit") }}</div>

      <div class="idiomaSubTitle">{{ t("app.idioma.sub_tit_1") }}</div>

      <div class="idiomaBody">
        <div v-for="(idioma, i) in idiomas" :key="i" class="idiomaBodyRow">
          <div
            class="idiomaBodyItem"
            :class="idioma[2] == locale ? 'idiomaBodyItemSelected' : ''"
            @click="cambiarIdioma(idioma)"
          >
            <!-- <span>{{ idioma[1].toUpperCase() }}</span> -->
            <span>{{ t(`variables.idiomas[${idioma[0]}]`) }}</span>
          </div>
        </div>
      </div>

      <div class="idioma_text_demo">
        <p>{{ t(`app.idioma.idiomas_muestra`) }}</p>
        <p></p>
      </div>

      <div class="idiomaBtn">
        <btnAction
          :textBtn="t('app.comunes.btns.guardar')"
          :disabled="!btnActive"
          @action="guardarCambio"
          colorBack="azul"
          colorText="blco"
          :estadoBtn="estadoBtn"
          :msg_completar="t('app.comunes.btns.seleccionar_nuevo_idioma')"
        />
      </div>

      <div class="idiomaFooter">
        <enviosSeguros @callEnvioSeguroModal_="callEnvioSeguroModal" />
      </div>
    </div>
  </screen_width_cont>
</template>

<script>
import { menuController, modalController } from "@ionic/vue";
import headerBack from "../components/generales/headerBack.vue";
import { useI18n } from "vue-i18n";
import { mapMutations, mapState } from "vuex";
import btnAction from "../components/generales/btnAction.vue";
import envioSeguroHome from "./modales/enviosSegurosHome.vue";
import enviosSeguros from "../components/generales/envios_seguros.vue";
import screen_width_cont from "../components/menuSp/screen_width_cont.vue";

export default {
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  components: {
    headerBack,
    btnAction,
    enviosSeguros,
    screen_width_cont,
  },
  data() {
    return {
      idioma_previo: this.locale,
    };
  },
  computed: {
    ...mapState([
      "menuSpDesde",
      "configuracion",
      "desde",
      "nav_stack",
      "widthScreen",
    ]),

    idiomas() {
      let aux = [];
      if (this.configuracion && this.configuracion.data.auxiliares.idiomas[0]) {
        aux = this.configuracion.data.auxiliares.idiomas[0];
      }
      return aux;
    },
    estadoBtn() {
      let aux = "inactive";
      if (this.idioma_previo != this.locale) {
        aux = "active";
      }
      return aux;
    },
  },
  methods: {
    ...mapMutations([
      "updateIdiomaPreferencia",
      "updateBloquearNavegacion",
      "set_nav_stack",
      "setshowSpinner"
    ]),

    backLocal() {
      // if(this.menuSpDesde.menuSp) menuController.open("firstMenu");
      // if(this.desde == "beneficiarioNuevo"){
      //   this.$router.push({ name: "nuevoDestinatarioForm", replace: true });
      // }else{
      //   this.$router.push({ name: this.menuSpDesde.desde, replace: true });
      // }

      if (this.nav_stack.menuSp) {
        menuController.open("firstMenu");
      }
      let aux = [...this.nav_stack.desde];
      let destino = aux.pop();
      this.set_nav_stack({ desde: aux, menuSp: false });
      this.$router.push({ name: destino, replace: true });
    },
    cambiarIdioma(idiomaSelected) {
      let selectedIdiomaObj =
        this.configuracion.data.auxiliares.idiomas[0].find((idioma) => {
          return idioma[0] == idiomaSelected[0];
        });
      this.updateIdiomaPreferencia({
        val: idiomaSelected[0],
        text: selectedIdiomaObj[1],
        cod: selectedIdiomaObj[2],
      });
      this.locale = selectedIdiomaObj[2];
      localStorage.setItem("idiomaPreferencia", idiomaSelected[0]);
    },
    async callEnvioSeguroModal() {
      this.updateBloquearNavegacion(true);
      const modal = await modalController.create({
        component: envioSeguroHome,
      });
      modal.onDidDismiss().then(() => {
        this.updateBloquearNavegacion(false);
      });
      return modal.present();
    },
    guardarCambio() {
      if (this.idioma_previo != this.locale) {
        this.idioma_previo = this.locale;
      }
      this.setshowSpinner(false);
      this.backLocal();
    },
  },
  mounted() {
    console.log(this.locale);
  },
  ionViewWillLeave() {
    if (this.idioma_previo != this.locale) {
      this.locale = this.idioma_previo;
    }
  },
};
</script>

<style>
.idiomaTitle {
  margin-top: 2rem;

  color: #801b1c;
}
.idiomaSubTitle {
  margin-top: 1.5625rem;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #614ad3;
}
.idiomaBody {
  margin-top: 1.125rem;
  /* height: 21rem; */
  /* min-height: 21rem; */
}
.idioma_text_demo {
  margin-top: 3rem;
  text-align: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.3rem;
  letter-spacing: 0.5px;
  color: #787878;
}
.idiomaBodyRow {
  padding-bottom: 1.25rem;
}
.idiomaBodyItem {
  padding-left: 1rem;
  border-radius: 6px;
  height: 2.75rem;
  min-height: 2.0625rem;
  background: rgba(225, 225, 225, 0.4);
  display: flex;
  justify-items: center;
  align-items: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2rempx;
  color: #787878;
  letter-spacing: 1px;
}
.idiomaBodyItemSelected {
  background: #787878;
  /* font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 11px; */
  color: #ffffff;
}
.idiomaBtn {
  margin-top: 3rem;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
}
.idiomaFooter {
  margin-top: 0.95rem;
}
</style>